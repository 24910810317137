<template>
  <div>
    <div class="row justify-content-start">
      <div class="col-12">
          <div class="form-group mb-3">
            <label for="subject">
                Subject
            </label>
            <input type="text" v-model="data.subject" id="subject"
                class="form-control" placeholder="Write subject" v-validate="'required'" name="subject"/>
            <span class="text-danger font-sm"  
            v-show="errors.has('subject')">{{ errors.first('subject') }}</span>
          </div>
      </div>
      <div class="col-12 mb-3">
          <quill-editor v-model="data.content" style="min-height: 100px;" :options="editorOption" />
      </div>
      <div class="col-12">
        <label class="">Selected Users({{selectedUsers.length}})</label>
        <multiselect :searchable="false" placeholder="Click to view" label="first_name"
          track-by="id" :options="selectedUsers" :option-height="104" :show-labels="false">
          <template slot="option" slot-scope="props">
            <div class="d-flex align-items-center">
              <div class="option__desc">
                <p class="option__title mb-0">{{ props.option.first_name }}</p>
                <span class="">{{ props.option.email }}</span>
              </div>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="form-group text-left mt-3 mb-0">
      <button class="btn btn-primary" @click.prevent="createData()" type="button">Send</button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default{
  props:{
    selectedUsers:{
      type: Array,
      required: true
    }
  },
  components:{
    Multiselect,
  },
  data() {
    return {
      data: {
        content: "",
        subject: "",
        buyers: this.selectedUsers
      },
      editorOption: {
        theme: "snow",
        placeholder: "Enter Description",
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image']
          ],
        }
      },
    }
  },
  methods: {
    createData() {
      this.$validator.validateAll().then(result => {
        if (!result || !this.data.content) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
          this.$http.post("/orders/send-mail", formData)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
              this.$emit('feedback');
              this.resetForm();
            }
          })
      })
    },
    resetForm() {
      this.data = {
        content: "",
        subject: "",
        buyers: this.selectedUsers,
      }
    },
  },
}
</script>
